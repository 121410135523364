import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function purchasepaidUseList(config) {
  // Use toast
  const toast = useToast()

  const refListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'id', label: 'ID', sortable: true },
    { key: 'purchase_order_id', label: '采购单ID', sortable: true },
    { key: 'amount', label: '付款金额', sortable: true },
    { key: 'pay_date', label: '付款日期', sortable: true },
    { key: 'memo', label: '备注'},
    { key: 'attachments', label: '相关附件'},
    // { key: 'state', label: '状态', sortable: true },
    { key: 'creator', label: '添加人', sortable: true },
    { key: 'create_time', label: '添加时间', sortable: true },
    // { key: 'update_time', label: '更新时间', sortable: true },
    // { key: 'updator', label: '更新人ID', sortable: true },
    //     { key: 'actions', label: '操作' },
  ]

  const start = ref(1)
  const limit = ref(50)
  const limitOptions = [25, 50, 100]
  const listTotals = ref(0)
  const searchKey = ref('')
  const orderBy = ref('order_id')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: limit.value * (start.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (start.value - 1) + localItemsCount,
      of: listTotals.value,
    }
  })

  const refetchData = () => {
	  refListTable.value.refresh()
  }

  watch([start, limit, searchKey], () => {
    refetchData()
  })

  const searchList = (ctx, callback) => {
    store
      .dispatch('purchasepaid/search', {
        search_key: searchKey.value,
        start: start.value,
        limit: limit.value,
        order_by: orderBy.value,
        order_desc: isSortDirDesc.value === true ? 'desc':'',
        purchase_order_id:config.purchase_order_id
      })
      .then(response => {
        const data = response.data.data
        const list = data.ext.list
        listTotals.value = data.ext.totals
        let paid =0
        for (let i = 0; i < list.length; i++) {
          if (list[i])
            paid+=list[i].amount
        }

        callback(list)
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            title: '付款明细列表获取错误',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    searchList,
    tableColumns,
    limit,
    start,
    listTotals: listTotals,
    dataMeta,
    limitOptions,
    searchKey,
    orderBy,
    isSortDirDesc,
    refListTable,

    refetchData,

    // Extra Filters
  }
}
