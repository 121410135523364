import { render, staticRenderFns } from "./PurchasePaidList.vue?vue&type=template&id=6ddbef72&scoped=true&"
import script from "./PurchasePaidList.vue?vue&type=script&lang=js&"
export * from "./PurchasePaidList.vue?vue&type=script&lang=js&"
import style0 from "./PurchasePaidList.vue?vue&type=style&index=0&id=6ddbef72&prod&lang=scss&scoped=true&"
import style1 from "./PurchasePaidList.vue?vue&type=style&index=1&id=6ddbef72&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ddbef72",
  null
  
)

export default component.exports